import tw from 'twin.macro'

const Container = tw.div`grid grid-cols-6 relative md:(grid-cols-9) lg:(grid-cols-16)`
const Content = tw.div`px-4 row-start-1 col-start-1 col-end-7 md:(pr-0 md:col-start-2 col-end-10) lg:(col-start-3 col-end-17 px-2)`
const Stepper = tw.div`flex w-max justify-start items-center relative pl-3 space-x-5 md:(items-start mb-0)`
const Step = tw.div`w-20 h-20 text-4xl rounded-full bg-gray-50 flex items-center justify-center font-thin text-gray-800 md:(sticky top-28) lg:top-24`
const Line = tw.div`hidden h-full border-r border-gray-600 border-dashed absolute left-8 top-0 w-0 -z-1 md:(block)`

const PhasePre = tw.p`capitalize text-base text-gray-400 mb-2 md:(pt-8)`
const PhaseTitle = tw.h2`text-4xl font-thin leading-tighter text-black col-span-full max-w-xs md:(max-w-lg)`
const PhaseSecondaryTitle = tw.h3`max-w-md font-thin text-black leading-tight text-4xl my-auto`

const PhaseCategory = tw.p`capitalize text-base text-gray-400`
const CategoryPrimaryText = tw.p`text-2xl font-thin text-black md:(text-4xl)`
const CategoryListText = tw.p`text-black text-base font-light`
const Description = tw.p`text-gray-500 text-lg font-sans font-light leading-relaxed my-6 md:(max-w-xl)`

export {
  Container,
  Stepper,
  Step,
  Line,
  Content,
  PhasePre,
  PhaseTitle,
  PhaseCategory,
  CategoryPrimaryText,
  CategoryListText,
  PhaseSecondaryTitle,
  Description,
}
